<template>
  <div >    
   
          <div class="formBox" v-if="showForm">
              <span class="t">{{addForm.id?'编辑':'新增'}}管线</span>
              <el-divider></el-divider>
                  <el-form ref="addForm" class="searchForm"  :model="addForm" :rules="rules" label-width="80px">
                        <el-form-item label="管线名称" prop="lineName" size="mini">
                          <el-input v-model="addForm.lineName"  placeholder="请输入管线名称"></el-input>
                        </el-form-item>
                        <el-form-item label="使用状态" prop="useStatus" size="mini">
                          <el-select v-model="addForm.useStatus" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="(item, index) in useStatusList"
                              :key="index"
                              :label="item.name"
                              :value="String(item.value)"
                            ></el-option>
                          </el-select>
                        </el-form-item>
<!--                    <el-form-item label="埋设方式" prop="buriedType" size="mini">-->
<!--                      <el-select v-model="addForm.buriedType" placeholder="请选择" style="whith:100%">-->
<!--                        <el-option-->
<!--                            v-for="(item) in buryMode"-->
<!--                            :key="item"-->
<!--                            :label="item"-->
<!--                            :value="item"-->
<!--                        ></el-option>-->
<!--                      </el-select>-->
<!--                    </el-form-item>-->
                    <el-form-item label="建设年度" prop="buildDate" size="mini">
                      <el-select size="mini" clearable v-model="addForm.buildDate" style="width: 100%">
                        <el-option
                            v-for="item in yearArray"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
<!--                        <el-form-item label="燃气类型" prop="gasType" size="mini">-->
<!--                          <el-select v-model="addForm.gasType" placeholder="请选择" style="whith:100%">-->
<!--                            <el-option-->
<!--                              v-for="(item, index) in gasTypeList"-->
<!--                              :key="index"-->
<!--                              :label="item.name"-->
<!--                              :value="String(item.id)"-->
<!--                            ></el-option>-->
<!--                          </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="压力级别" prop="stressLevel" size="mini">
                          <el-select v-model="addForm.stressLevel" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="(item, index) in stressLevelList"
                              :key="index"
                              :label="item.name"
                              :value='String(item.id)'
                            ></el-option>
                          </el-select>
                        </el-form-item>     
                        <el-form-item label="材质规格" prop="material" size="mini">
                          <!-- <el-select v-model="addForm.material" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="(item, index) in materialList"
                              :key="index"
                              :label="item.name"
                              :value="String(item.id)"
                            ></el-option>
                          </el-select> -->
                          <el-cascader
                          :options="materialList"
                          :show-all-levels="false"
                          v-model="addForm.materialName"
                          :props="{ value: 'code', label: 'name',}"
                          clearable
                          ref="cascader"
                          collapse-tags
                          @change="SearchLineChange"></el-cascader>
                        </el-form-item>
                        <el-form-item label="埋设方式" prop="buriedType" size="mini">
                          <el-select v-model="addForm.buriedType" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="(item) in buriedTypeList"
                              :key="item.value"
                              :label="item.name"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                    <el-form-item label="穿越方式" v-if="addForm.buriedType == '2'" prop="buriedType" size="mini">
                      <el-select v-model="addForm.through" placeholder="请选择" style="whith:100%">
                        <el-option
                            v-for="(item) in crossMode"
                            :key="item"
                            :label="item"
                            :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="管线长度" prop="length" size="mini">
                      <el-input name="limitLength"  type="number" maxlength="15" v-model="addForm.length"  placeholder="请输入设备长度">
                        <template slot="append">m</template>
                      </el-input>
                    </el-form-item>
                    <el-form-item v-if="addForm.buriedType != '1'" label="埋深" prop="burialDepth" size="mini">
                      <el-input name="limitLength" type="number" maxlength="6"  v-model="addForm.burialDepth"  placeholder="请输入设备埋深">
                        <template slot="append">m</template>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="壁厚" prop="thickness" size="mini">
                      <el-input  name="limitLength" type="number"   @input="thickness" v-model="addForm.thickness" placeholder="请输入壁厚">
                        <template slot="append">mm</template>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="内径" prop="bore" size="mini">
                      <el-input  name="limitLength" type="number" disabled  maxlength="4" v-model="addForm.bore" placeholder="请输入内径">
                        <template slot="append">mm</template>
                      </el-input>
                    </el-form-item>
                        <el-form-item label="颜色" prop="styleColor" size="mini">
                            <el-color-picker v-model="addForm.styleColor"></el-color-picker>
                        </el-form-item>  
                        <el-form-item label-width="0px" class="searchBt">
                          <el-button
                            size="mini"
                            type="primary"
                            class="searchBtn"
                            @click="save"
                            >保存</el-button
                          >
                          <el-button
                            size="mini"
                            class="searchBtn"
                            @click="closePDraw"
                            >取消</el-button
                          >
                        </el-form-item>
                  </el-form>
          </div>

  </div>
</template>

<script>

import {saveLine,selectDictListByParentCode } from '@/apis/commonType'
import { patrolGetDict } from "@/RequestPort/maintenance";
import { geoOws } from '@/apis/geo'
export default {
  name: 'formEdit',
  props:{
    showForm:{
      type:Boolean,
      default:false
    }, 
    addForm:{
      type:Object,
      default: function(){
        return {}
      }
    },
    polyline1:{
      type:Object,
      default: null
    }
  },
  watch:{
    showForm(e){
      if(e == false){
        this.$emit('resetData')
      }
    },
  },
  data() {
    return {
      buryMode:[
        '地下',
        '架空',
        '穿越',
      ],
      crossMode:[
        '入钻造斜',
        '出钻造斜',
        '水平造斜',
      ],
      name: '管线',
      rules: {
        lineName: [
          { required: true, message: '请输入管线名称', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 0 到 100 个字符', trigger: 'blur' }
        ],
        useStatus: [
          { required: true, message: '请选择使用状态', trigger: 'change' }
        ],
        thickness: [
          { required: true, message: '请填写壁厚', trigger: 'change' }
        ],
        //  gasType: [
        //   { required: true, message: '请选择燃气类型', trigger: 'change' }
        // ],
         stressLevel: [
          { required: true, message: '请选择压力级别', trigger: 'change' }
        ],
        styleColor: [
          { required: true, message: '请选择颜色', trigger: 'change' }
        ],
        material:[
          { required: true, message: '请选择材质', trigger: 'change' }
        ],
        buriedType:[
          { required: true, message: '请选择埋深方式', trigger: 'change' }
        ],
        length:[
          { required: true, message: '请输入长度', trigger: 'blur' },
        ],
        bore:[
          { required: true, message: '请输入管径', trigger: 'blur' },
        ],
        buildDate:[
          { required: true, message: '请输入建设年度', trigger: 'change' }
        ],
      },
      // 使用状态
      useStatusList:[{name:'在建',value:'0'},{name:'停用',value:'1'},{name:'在用',value:'2'},{name:'规划',value:'3'}],
      gasTypeList:[],
      stressLevelList:[],
      // 材质
      materialList:[],
      // 埋设方式
      buriedTypeList:[
        {name:'地下',value:"0"},
        {name:'架空',value:"1"},
        {name:'穿越',value:"2"}
      ],
      nowYear:'',
      yearArray:[],
    }
  },
  computed: {},
   destroyed () {
    this.$eventBus.$off('setLocation') 
    this.$eventBus.$off('setLength') 
   },
  mounted() {
    // console.log(this.addForm,"shisha ")
    // this.addForm.ply = this.addForm.bore + (2*this.addForm.thickness)
    setTimeout((e)=>{
      let inputArray = document.getElementsByClassName('el-input__inner')
      if(inputArray.length > 0) {
        inputArray.forEach((item,index)=>{
          item.setAttribute('maxlength',10)
        })
      }
    },1000)
    this.$eventBus.$on('setLocation', data => { 
      this.setLocation(data)
    })
     this.$eventBus.$on('setLength', data => { 
      let points=data.getPath()
      this.setLength(points)
    })
    this.nowYear = new Date().getFullYear()
      let parames={
        parentCode:512
       }
    selectDictListByParentCode(parames).then(res => {
         if(res.code==200){
           this.stressLevelList=res.data
           // this.stressLevelList.forEach((item,index)=>{
           //   item.id = Number(item.id)
           // })
         }
      })



     let parames1={
        parentCode:'gasType'
       }
    selectDictListByParentCode(parames1).then(res => {
         if(res.code==200){
           this.gasTypeList=res.data
         }
      })

      
     let parames2={
        parentCode:'texture'
       }
    // selectDictListByParentCode(parames2).then(res => {
    //      if(res.code==200){
    //        this.materialList=res.data
    //      }
    //   })
    // 获取材质
    patrolGetDict({pid:'524,518,70,46'}).then(res => {
        if(res.code == 200) {
          this.materialList = res.data[0].children[0].children
        } else {
          this.$message.error(res.msg)
        }
      })
    let parames3={
        parentCode:'buriedType'
    }
    // selectDictListByParentCode(parames3).then(res => {
    //      if(res.code==200){
    //        this.buriedTypeList=res.data
    //      }
    //   })
    this.yearArrayFun()

  },
  methods: {
    /**
     * 级联菜单选择
     */
    SearchLineChange(val){
      // 拿出公称直径
      let opt = this.$refs['cascader'].$refs.panel.getCheckedNodes()
      this.addForm.materialName = val[1]
      this.addForm.material = val[1]
      opt[0].data.value1 && (this.addForm.ply = opt[0].data.value1.split("*")[0])
      opt[0].data.value1 && (this.addForm.thickness = opt[0].data.value1.split("*")[1])
      if(this.addForm.thickness) {
        // 计算内径 公称直径-2*壁厚
        this.addForm.bore = this.addForm.ply-(2*this.addForm.thickness)
      }
      // let materialList = []
      // val.forEach(el => {
      //       materialList.push(el[1])
      // })
      
      
    },
    /**
     * 填写完壁厚
     */
    thickness(value){
      // 限制只能输入四位数
      if(value.length>4) this.addForm.thickness=value.slice(0,4)
      // 判断材质规格选择后才能计算
      if(this.addForm.ply) {
        // 计算内径 公称直径-2*壁厚
        this.addForm.bore = this.addForm.ply-(2*this.addForm.thickness)
      }
    },
    setLength(point){
        let allLength = 0
        point.forEach((item,index)=>{
          if(index < point.length-1){
            allLength += this.getMapDistanceApi([item.lng,item.lat],[point[index+1].lng,point[index+1].lat])
          }
        })
      this.$parent.addForm.length = allLength.toFixed(2)
    },
    getMapDistanceApi(position1,position2){
      var lng1 = position1[0]
      var lat1 = position1[1]
      var lng2 = position2[0]
      var lat2 = position2[1]
      var radLat1 = lat1*Math.PI / 180.0;
      var radLat2 = lat2*Math.PI / 180.0;
      var a = radLat1 - radLat2;
      var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
          Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
      s = s *6378.137 ;
      s = Math.round(s * 10000) / 10000;
      s = s * 1000
      return s;
    },
    yearArrayFun(){
      if(this.nowYear >= 2016){
        this.yearArray.push(this.nowYear)
        this.nowYear --
        this.yearArrayFun()
      }else{
        return
      }
    },
    resetFields(){
      if(this.$refs['addForm']){
        this.$refs['addForm'].resetFields()
      }
    },
    setLocation(polyline1){
      if(polyline1){
          this.addForm.locations=polyline1.getPath()
      }
    },
    /**
     * 保存
     */
    save(){
      this.$eventBus.$emit('vectorLineEdit')
      let that=this
      this.$nextTick(()=>{
         that.$refs['addForm'].validate(valid => {
          if (valid) {
               that.getNetwork()
          }
         })
      })
      
      
    },
    getNetwork(){
      let file=this.getfile(this.addForm.locations)
      let parames={
          service:'WFS',
          request:'GetFeature',
          typeName:'gas:patrol_networks',
          maxFeatures:'50',
          outputFormat:'application/json',
          version:'1.0.0',
          filter:file
      }
      let parentNetId = this.$store.getters.GetPoly.id
      geoOws(parames).then(res => {
        if(!parentNetId){
          if(res.features&&res.features[0]){
            this.addForm.networkId=res.features[0].properties.id
            this.saveApi()
          }else{
            this.addForm.networkId= ''
            this.saveApi()
          }
        }else if(res.features&&res.features[0]){
         this.addForm.networkId=res.features[0].properties.id
          if(parentNetId&&parentNetId==res.features[0].properties.id){
            this.saveApi()
          }else{
           return  this.$message.error('请画在片区内')
          }
        }else{
          return this.$message.error('请画在片区内')
        }
       }
      )
    },
    /**
     * 新增管线
     */
    saveApi(){
          let parames=this.addForm
          saveLine(parames).then(res => {
          if(res.code==200){
            this.$message.success('操作成功')
            this.$emit('save')
            this.$emit('GetNetworkDetailFun')
            this.$emit('getListArea')
          }
        })
    },
    getfile(lnglat){
      let fileStr=lnglat.join(' ')
      let file='<Filter xmlns:gml="http://www.opengis.net/gml">'+
                       ' <And><PropertyIsGreaterThan>'+
                        '  <PropertyName>general_status</PropertyName>'+
                         ' <Literal>0</Literal>'+
                        ' </PropertyIsGreaterThan> <Intersects>'+
                        ' <PropertyName>location</PropertyName>'+
                      '   <gml:LineString>'+
                            '<gml:coordinates>'+fileStr+'</gml:coordinates>'+
                        ' </gml:LineString>'+
                    '  </Intersects></And>'+
                '  </Filter>'
      return file
    },
    closePDraw(){
      this.$emit('closePDraw')
    }
  }
}
</script>

<style lang="less" scoped>

.searchForm{margin:15px 15px 0 5px;}
.searchBt{text-align: center;}
.formBox{position:absolute;left:10%;top:100px;background: #fff;width: 300px;padding-top:10px;z-index: 3;
.t{padding-left:10px;font-size: 16px;}
::v-deep{.el-divider--horizontal{margin-top:10px;margin-bottom:10px}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 100%;}
}
}
</style>
